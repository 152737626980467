<template>
  <div id="app">
    <task-link-input @start-loading="loading = true"></task-link-input>
    <graph :loading="loading"></graph>
  </div>
</template>

<script>
import TaskLinkInput from './components/TaskLinkInput.vue'
import Graph from './components/Graph.vue'

export default {
  name: 'App',
  components: {
    TaskLinkInput,
    Graph
  },
  data() {
    return {
      loading: false
    }
  },
  mounted() {
    this.$root.$on('data-ready', ()=> this.loading = false)
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
