export function buildGraph(input) {
  const links = buildLinks(input.dependencies)
  const nodeData = buildNodes(input.dependencies, links)
  const nodeDataWithRoot = addRootNode(nodeData, input.root)
  return nodeDataWithRoot
}

function buildLinks(input) {
  const linkTypes = {
    blocks: {connection: "--->", id: "origin", next: "dependencyId"},
    is_blocked_by: {connection: "--->", id: "dependencyId", next: "origin"},
    is_related_to: {connection: "-.-", id: "dependencyId", next: "origin"},
    is_child_to: {connection: "--->", id: "origin", next: "dependencyId", linkStyle: "stroke:green"},
    is_parent_to: {connection: "--->", id: "dependencyId", next: "origin", linkStyle: "stroke:green"},
    is_duplicate_to: {connection: "-.-", id: "dependencyId", next: "origin", linkStyle: "stroke:red"},
  }
  const result = []
  for (var dependency of input) {
    const newLink = {
      id: dependency[linkTypes[dependency.type].id],
      next: [dependency[linkTypes[dependency.type].next]],
      link: linkTypes[dependency.type].connection,
      linkStyle: linkTypes[dependency.type].linkStyle
    }
    result.push(newLink)
  }
  return result
}

function buildNodes(input, nodeData) {
  let nodes = []
  for (var dependency of input) {
    if (!nodes.some(x => x.id == dependency.dependencyId)) {
      nodes.push({
        id: dependency.dependencyId,
        text: `<b>${clearName(dependency.name)}</b><br>${clearName(dependency.list)}`,
        url: dependency.url
      })
    }
  }
  nodeData.push(...nodes)
  return nodeData
}

function clearName(name) {
  name = name.replace("(", "")
  name = name.replace(")", "")
  return name
}

function addRootNode(nodeData, root) {
  nodeData.push({
    id: root.id,
    text: `<b>${clearName(root.name)}</b><br>${clearName(root.list)}`,
    url: root.url,
    style: "fill:green,color:white,font-size:20px",
    edgeType: "hexagon"
  })
  return nodeData
}