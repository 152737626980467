<template>
  <form @submit.prevent="onLinkInput">
    <b-container>
      <b-row class="my-1" align-v="center">
        <b-col sm="3">
          <label for="link"><b>Trello card URL:</b></label>
        </b-col>
        <b-col sm="9">
          <b-form-input 
            type="text"
            id="link" 
            :state="isValidLink" 
            placeholder="https://trello.com/c/xxxXXXxx"
            v-model="link">
          </b-form-input>
          <b-form-invalid-feedback id="link-feedback">
            This is not a valid Trello link.
          </b-form-invalid-feedback>
        </b-col>
      </b-row>
      <b-row class="my-1" align-v="center">
        <b-col sm="3">
          <label for="depth"><b>Max dependency depth:</b></label>
        </b-col>
        <b-col sm="9">
          <b-form-input 
            type="number"
            min="0"
            id="depth" 
            v-model="maxDepth">
          </b-form-input>
        </b-col>
      </b-row>
      <b-row class="my-1" align-v="center">
        <b-col sm="12">
          <b-button variant="primary" type="submit">Submit</b-button>
        </b-col>
      </b-row>
    </b-container>
  </form>
</template>

<script>
import {validateLink, getIdFromLink, getCardDependencies} from '../scripts/trello'

export default {
  name: "TaskLinkInput",
  data() {
    return {
      isValidLink: null,
      link: null,
      maxDepth: 5
    }
  },
  methods: {
    async onLinkInput() {
      this.isValidLink = validateLink(this.link)
      let result
      if (this.isValidLink) {
        this.$emit('start-loading')
        result = await getCardDependencies(getIdFromLink(this.link), this.maxDepth)
        this.$root.$emit('data-ready', result)
      }
    }
  }
}
</script>
