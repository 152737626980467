import axios from 'axios'
import rateLimit from 'axios-rate-limit'

export function getIdFromLink (link) {
  const regExp = /^https:\/\/trello\.com\/c\/(\w{8})/ 
  return link.match(regExp)[1]
}

export function validateLink (link) {
  if (!link) return false
  const regExp = /^https:\/\/trello\.com\/c\/\w{8}/ 
  return !!link.match(regExp)
}

export async function getCardDependencies(id, maxNesting = 5) {
  const http = setAxiosRateLimit()
  const {root, lists} = await getRootAndLists(id, http)
  const options = {
    id: id,
    http: http,
    dependencies: [],
    nesting: 0,
    maxNesting: maxNesting,
    lists: lists
  }
  const dependencies = await getDependencyGraph(options)
  return {dependencies, root}
}


function setAxiosRateLimit() {
  return rateLimit(axios.create(), { maxRequests: 90, perMilliseconds: 10000})
}

async function getRootAndLists(id, http) {
  let root = await getCard(id, http)
  let lists = []
  lists[root.boardId] = await getListNames(root.boardId, http)
  root.list = lists[root.boardId].find(x => x.id == root.listId).name
  return {root, lists}
}

async function getCard(id, http) {
  const APIparams = getAPIparams()
  const url = `https://api.trello.com/1/cards/${id}?key=${APIparams.api_key}&token=${APIparams.token}`
  const response = await http.get(url)
  return {
    url: response.data.url,
    name: response.data.name,
    boardId: response.data.idBoard,
    listId: response.data.idList,
    id: id
  }
}

function getAPIparams() {
  return {
    api_key: process.env.VUE_APP_API_KEY,
    token: process.env.VUE_APP_TOKEN
  }
}

async function getDependencyGraph(options) {
  const attachments = await loadAttachments(options.id, options.http)
  const newDependencies = await parseDependencies(attachments, options)
  options.nesting++
  for (var dependency of newDependencies) {
    if (!dependency.resolved && 
      !options.dependencies.some(x => 
        (x.origin == dependency.dependencyId && 
        x.dependencyId == dependency.origin) ||
        (x.origin == dependency.origin && 
        x.dependencyId == dependency.dependencyId)
        )) {
      options.dependencies.push(dependency)
      if (options.nesting < options.maxNesting)  {
        options.id = dependency.dependencyId
        await getDependencyGraph(options)
      }
    }
  }
  options.nesting--
  return options.dependencies
}

async function loadAttachments(id, http) {
  const APIparams = getAPIparams()
  const url = `https://api.trello.com/1/cards/${id}/attachments?key=${APIparams.api_key}&token=${APIparams.token}`
  const response = await http.get(url)
  return response.data
}

async function parseDependencies(attachments, options) {
  const dependencies = []
  for (var attachment of attachments) {
    const type = getDependencyType(attachment)
    if (type) {
      const dependencyId = getIdFromLink(attachment.url)
      const dependencyCard = await getCard(dependencyId, options.http)
      if (!(dependencyCard.boardId in options.lists)) {
        options.lists[dependencyCard.boardId] = await getListNames(dependencyCard.boardId, options.http)
      }
      const dependency = {
        origin: options.id,
        name: dependencyCard.name,
        url: dependencyCard.url,
        listId: dependencyCard.listId,
        list: options.lists[dependencyCard.boardId].find(x => x.id == dependencyCard.listId).name,
        type: type,
        dependencyId: dependencyId,
        resolved: getResolveStatus(attachment)
      }
      dependencies.push(dependency)
    }
  }
  return dependencies
}

function getDependencyType(input) {
  const url = new URL(input.url)
  const urlSearch = new URLSearchParams(url.hash)
 return urlSearch.get('dependencyType')
}

function getResolveStatus(input) {
  const url = new URL(input.url)
  const urlSearch = new URLSearchParams(url.hash)
  const param = urlSearch.get('resolved')
  return param == "true"
}

async function getListNames(boardId, http) {
  const APIparams = getAPIparams()
  const url = `https://api.trello.com/1/boards/${boardId}/lists?key=${APIparams.api_key}&token=${APIparams.token}`
  const response = await http.get(url)
  return response.data
}